<template>
  <footer class="ho-the-footer">
    <nav class="inner">
      <!--
        <ul class="content-list -logo -musv">
          <li class="item">
            <HaLink force-anchor-link to="/"><LogoEvent /></HaLink>
          </li>
        </ul>
      -->
      <ul
        v-for="(list, index) in footerMenu"
        :key="index"
        :class="{
          'content-list -hidden': list.disabled === true,
          'content-list -sns': list.key === 'sns',
          'content-list': (!list.disabled && list.key !== 'sns') === true,
        }"
      >
        <template v-if="list.key !== 'logo'">
          <li class="item -title">{{ list.title }}</li>
        </template>
        <li
          v-for="(item, i) in list.item"
          :key="i"
          :class="{
            'item -hidden': item.disabled === true,
            'item -sns': list.key === 'sns',
            item: (!item.disabled && list.key !== 'sns') === true,
          }"
        >
          <template v-if="list.key === 'sns'">
            <HaLink
              :force-anchor-link="item.to.includes('/2024Winter')"
              :to="item.to"
              :blank="true"
              class="footer-link -sns"
            >
              <div :class="'icon -' + item.icon">
                <template v-if="item.icon === 'twitter'">
                  <LogoTwitter class="image" />
                </template>
                <template v-if="item.icon === 'youtube'">
                  <LogoYoutube class="image" />
                </template>
                <template v-if="item.icon === 'facebook'">
                  <LogoFacebook class="image" />
                </template>
                <template v-if="item.icon === 'discord'">
                  <LogoDiscord class="image" />
                </template>
                <template v-if="item.icon === 'instagram'">
                  <LogoInstagram class="image" />
                </template>
              </div>
              <span class="text -sns">
                {{ item.text }}
              </span>
            </HaLink>
          </template>
          <template v-else-if="item.to.startsWith('http')">
            <HaLink
              :force-anchor-link="item.to.includes('/2024Winter')"
              :to="item.to"
              :blank="true"
              class="footer-link"
            >
              <span class="text">
                {{ item.text }}
              </span>
            </HaLink>
          </template>
          <template v-else>
            <!-- FIXME: https://hikky.atlassian.net/browse/V24W-1782 対応のため、aタグでの遷移とする -->
            <HaLink
              :force-anchor-link="
                item.to.includes('/2024Winter') || item.to.includes('/docs/')
              "
              :to="item.to"
              class="footer-link"
            >
              <span class="text">
                {{ item.text }}
              </span>
            </HaLink>
          </template>
        </li>
      </ul>
      <ul class="content-list -logo">
        <li class="item">
          <HaLink :to="vrchatUrl" :blank="true" class="vrchat-logo">
            <LogoVrchat
              class="image"
              role="img"
              :aria-label="`${$t('footer.logo.vrchat')}`"
            />
          </HaLink>
        </li>
        <li class="item">
          <HaLink :to="hikkyUrl" :blank="true" class="hikky-logo">
            <LogoHikky class="image" />
          </HaLink>
        </li>
      </ul>
    </nav>
    <div class="footer-bottom">
      <HaLink :to="privacyUrl" :blank="true" class="privacy">
        {{ $t('footer.privacypolicy') }}
      </HaLink>
      <small class="copyright"
        >&copy; HIKKY Co., Ltd. All rights reserved.</small
      >
    </div>
  </footer>
</template>
<script lang="ts" setup>
import LogoDiscord from '@/assets/icons/icon-discord.svg?component'
import LogoInstagram from '@/assets/icons/icon-instagram.svg?component'
import LogoYoutube from '@/assets/icons/icon-youtube.svg?component'
import LogoFacebook from '@/assets/icons/logo-facebook.svg?component'
import LogoTwitter from '@/assets/icons/x_icon_w.svg?component'
import LogoVrchat from '@/assets/images/2024Winter/vrchat_white_fit.svg?component'
import LogoHikky from '@/assets/images/logo-hikky_white.svg?component'
import { content } from '@/utils/content'
import { isBeforeStartEvent } from '@/utils/date-control'

interface ItemType {
  text: string
  to: string
  disabled: boolean
  icon?: string
}

interface ListType {
  title: string
  disabled: boolean
  key: string
  item: ItemType[]
}
// TODO: migration   mixins: [AvoidNuxtInstance],
const i18n = useI18n()
const $t = i18n.t

// TODO: 公開内容の変更に合わせてここを修正。disabledを変更で公開変更できる
const footerMenu = computed((): ListType[] => {
  const url = content.url
  const contentsColumn = {
    title: $t('footer.contents.title').toString(),
    disabled: true,
    key: 'contents',
    item: [
      {
        // NOTE: カタログ公開前に掲出
        text: $t('footer.contents.world').toString(),
        to: '/2024Winter/world',
        disabled: isStartCatalog(),
      },
      {
        // NOTE: カタログ公開後に掲出
        text: $t('footer.contents.catalog').toString(),
        to: '/2024Winter/world',
        disabled: isBeforeStartCatalog(),
      },
      {
        text: $t('footer.contents.official').toString(),
        to: '/2024Winter/uc/official',
        disabled: true,
      },
      {
        text: $t('footer.contents.streaming').toString(),
        to: '/2024Winter/uc/streaming',
        disabled: true,
      },
      {
        text: $t('footer.contents.event').toString(),
        to: '/2024Winter/uc/event',
        disabled: true,
      },
      {
        text: $t('footer.contents.project').toString(),
        to: '/2024Winter/uc/project',
        disabled: true,
      },
      {
        text: $t('footer.contents.visit').toString(),
        to: '/2024Winter/visit_announcement',
        disabled: true,
      },
      {
        text: $t('footer.contents.plans').toString(),
        to: '/2024Winter/uc/plans',
        disabled: true,
      },
      {
        text: $t('footer.contents.stamp').toString(),
        to: '/2024Winter/stamprally',
        disabled: isBeforeStartEvent(),
      },
      {
        text: $t('footer.contents.visit').toString(),
        to: `/2024Winter/visit_announcement`,
        disabled: isBeforeStartCatalog(),
      },
      {
        text: $t('footer.contents.real').toString(),
        to: '/2024Winter/real',
        disabled: true,
      },
    ],
  }
  contentsColumn.disabled = contentsColumn.item.every((item) => item.disabled)
  return [
    {
      title: $t('footer.about.title').toString(),
      disabled: false,
      key: 'about',
      item: [
        {
          text: $t('footer.about.about').toString(),
          to: '/about',
          disabled: false,
        },
        {
          text: $t('footer.about.access').toString(),
          to: '/access',
          disabled: false,
        },
        {
          text: $t('footer.about.credit').toString(),
          to: '/2024Winter/credit',
          disabled: isBeforeStartEvent(),
        },
        {
          text: $t('footer.about.launch_links').toString(),
          to: '/2024Winter/world/launch-links',
          disabled: isBeforeStartEvent(),
        },
        {
          text: $t('footer.about.license').toString(),
          to: '/docs/2024Winter/license',
          disabled: isBeforeStartEvent(),
        },
      ],
    },
    contentsColumn,
    {
      title: $t('footer.document.title').toString(),
      disabled: false,
      key: 'document',
      item: [
        /*
        {
          text: `${$t('footer.document.rule')}・${$t(
            'footer.document.udon'
          )}`,
          to: '/docs/submission_rules',
          disabled: false,
        },
        */
        {
          text: $t('footer.document.guideline').toString(),
          to: '/docs/2024winter/exhibition_guideline',
          disabled: false,
        },
        {
          text: $t('footer.document.terms').toString(),
          to: i18n.locale.value === 'ja' ? url.termsja : url.termsen,
          disabled: false,
        },
        {
          text: $t('footer.document.rule').toString(),
          to: '/docs/2024winter/submission_rules',
          disabled: false,
        },
        {
          text: $t('footer.document.faq').toString(),
          to: 'https://event.vket.com/docs/faq',
          disabled: false,
        },
      ],
    },
    {
      title: $t('footer.service.title').toString(),
      disabled: false,
      key: 'services',
      item: [
        {
          text: $t('footer.service.myvket').toString(),
          to: url.myvket,
          disabled: false,
        },
        {
          text: $t('footer.service.store').toString(),
          to: url.store,
          disabled: false,
        },
        {
          text: $t('footer.service.cloud').toString(),
          to: url.cloud,
          disabled: false,
        },
        {
          text: $t('footer.service.virtual_market').toString(),
          to: url.vket2024s,
          disabled: false,
        },
        {
          text: $t('footer.service.musv5').toString(),
          to: url.musv5,
          disabled: false,
        },
        {
          text: $t('footer.service.comv3').toString(),
          to: url.comv3,
          disabled: false,
        },
        {
          text: $t('footer.service.gamv').toString(),
          to: url.gamv,
          disabled: false,
        },
      ],
    },
    {
      title: $t('footer.contact.title').toString(),
      disabled: false,
      key: 'contact',
      item: [
        {
          text: $t('footer.contact.contact').toString(),
          to: '/2024Winter/contact',
          disabled: false,
        },
        {
          text: $t('footer.contact.company_booth').toString(),
          to: 'https://hikky.co.jp/biz/vket',
          disabled: false,
        },
        {
          text: $t('footer.contact.media').toString(),
          to: '/media',
          disabled: false,
        },
      ],
    },
    {
      title: $t('footer.sns.title').toString(),
      disabled: false,
      key: 'sns',
      item: [
        {
          text: $t('footer.sns.twitter').toString(),
          to: url.twitter,
          disabled: false,
          icon: 'twitter',
        },
        {
          text: $t('footer.sns.youtube').toString(),
          to: url.youtube,
          disabled: false,
          icon: 'youtube',
        },
        {
          text: $t('footer.sns.facebook').toString(),
          to: url.facebook,
          disabled: false,
          icon: 'facebook',
        },
        {
          text: $t('footer.sns.instagram').toString(),
          to: url.instagram,
          disabled: false,
          icon: 'instagram',
        },
      ],
    },
  ]
})

const vrchatUrl = content.url.vrchat
const hikkyUrl = content.url.hikky
const privacyUrl = computed(() =>
  i18n.locale.value === 'ja' ? content.url.privacy : content.url.privacyen
)
</script>

<style lang="scss" scoped>
@use '@/assets/styles/variables' as v;
@use '@/assets/styles/mixins' as m;

.ho-the-footer {
  background-color: v.$v24w-secondary-07;
  padding: v.$space-xxlarge v.$space-base v.$space-xlarge;
  position: relative;
  z-index: v.$zindex-footer;
  @include m.tb() {
    padding: v.$space-large v.$space-base;
  }

  > .inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;
    max-width: v.$pc-content-medium-width;
    padding-bottom: 40px;
    @include m.tb() {
      justify-content: center;
    }
  }
}

.content-list {
  width: 128px;
  @include m.tb() {
    margin: 12px;
  }

  &.-hidden {
    display: none;
  }

  &.-logo {
    height: 100%;
    @include m.tb() {
      height: auto;
      width: 128px;

      &.-musv {
        display: none;
      }
    }
  }

  &.-logo > .item {
    padding-right: 24px;
    width: 128px;
  }

  &.-sns > .item {
    height: 24px;
  }

  > .item {
    margin-bottom: v.$space-small;

    &:last-child {
      margin-bottom: 0;
    }

    &.-hidden {
      display: none;
    }

    &.-title {
      color: v.$white;
      font-size: 15px;
      font-weight: 800;
    }

    > .hikky-logo,
    > .vrchat-logo {
      .image {
        width: 100%;
      }
    }
  }
}

.footer-link {
  display: block;

  &.-sns {
    align-items: center;
    display: flex;
  }

  > .text {
    color: v.$white;
    font-size: 12px;
    font-weight: 400;

    &:hover,
    &:active {
      color: rgba(v.$white, 0.8);
    }

    &.-sns {
      display: block;
      flex: 1;
      width: 100%;
    }
  }

  > .icon {
    align-items: center;
    background-color: v.$black;
    border-radius: 50%;
    display: flex;
    height: 24px;
    justify-content: center;
    margin-right: v.$space-small;
    width: 24px;

    &.-twitter {
      background-color: v.$twitter-x;
    }

    &.-youtube {
      background-color: v.$white;
      padding: 3px;
    }

    &.-facebook {
      background-color: v.$facebook;
    }

    &.-discord {
      background-color: v.$discord;
      padding: 5px;
    }

    &.-instagram {
      background: v.$instagram-gradation;
      fill: #fff;
      padding: 4px;
    }

    > .image {
      // Safariだと100%指定しないとsvgが出てこない
      width: 100%;
    }
  }
}

.footer-bottom {
  text-align: center;

  > .privacy {
    color: v.$white-1;
    font-size: 12px;
    font-weight: 400;

    @include m.tb {
      color: v.$white;
    }

    &:hover,
    &:active {
      color: rgba(v.$white, 0.8);
    }
  }

  > .copyright {
    color: v.$white;
    display: block;
    font-size: 11px;
    padding-top: v.$space-base;
  }
}
</style>
